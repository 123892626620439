.refund-calculator {
  box-shadow: $box-shadow-headers, 0 1px 0 0 rgba($black, 0.075);;
  margin-bottom: 25px;
  padding: 25px 0;

  .calcValue {
    color: $primary;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
    }

    span {
      color: $gray-200;
      letter-spacing: 0.1em;
    }

    &.calcChargeback {
      color: $gray-600;
    }
  }
}
