.modal {
  max-width: 100%;

  .modal-dialog {
    align-items: center;
    display: flex;
    height: calc(100vh - 60px);

    &.offer-select-modal {
      max-width: 369px;
      margin: 0 auto;

      @media (max-width: 475px) {
        max-width: 100%;
      }

      .modal-title {
        padding-left: 0;
        text-align: left;
      }
    }
  }
  
  .modal-content {
    box-shadow: $box-shadow-dark;
  }

  .modal-header {
    border: 0;
    box-shadow: 0 1px 0 0 rgba($black, 0.075);
    margin-left: $grid-gutter-width;
    padding-left: 0;
    padding-right: $grid-gutter-width;

    h5 {
      padding-left: $grid-gutter-width;
      text-align: center;
      width: 100%;
    }
  }

  .modal-body {
    padding: $grid-gutter-width;

    @include media-breakpoint-down(sm) {
      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  
  .modal-footer {
    border: 0;
    box-shadow: 0 -1px 0 0 rgba(0, 33, 71, 0.075);
    margin-left: 25px;
    padding-right: 45px;
  }

  .close {
    color: $gray-300;
    font-size: 32px;
    margin-right: -$grid-gutter-width;
    outline: none;
    opacity: 1 !important;
    padding: 10px 18px 14px;
    transition: all 0.2s linear;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      color: $primary;
    }
  }
}
.modal-open{
  .modal{
    opacity: 1 !important;
  }
} 