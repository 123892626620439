@import 'src/assets/styles/variable.scss';

.selectLabel {
  width: 100%;

  &.disabled {
    span {
      z-index: 1;
      opacity: .7;
    }
  }

  span {
    background-color: #fff !important;
    em {
      top: -2px;
      padding: 1px 5px 2px;
    }
  }

  .error {
    height: 20px;
    position: relative;
    left: lendingusa_spacing_xs - 7;
    color: $accent_color;
    padding-left: 18px;
    font-size: 12px;
    word-wrap: break-word;
    font-size: 14px;
    margin: 0 0 $lendingusa_spacing_xs -2;
  }

  .select {
    margin: 0;
    background-color: #fff !important;
    // appearance: none !important;
    background-image: none;

    &:focus {
      color: #3f556e;
      border-color: #a9ccf3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(57,137,227,.25);
    }
  }
}