.page-brochures {
  .navbar {
    z-index: 10;
  }

  .container-body {
    padding: 0;
    max-width: none;
  }

  .brochures-img {
    // background: #e2eefb url(/images/brochures.jpg) 50% no-repeat;
    background-size: 135%;
    height: 0;
    padding-top: 55%;

    @include media-breakpoint-up(md) {
      background-size: cover;
      height: auto;
      min-height: calc(100vh - 70px);
      padding-top: 0;
    }
  }

  .brochure-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .max-container {
    max-width: 680px;
    padding: $grid-gutter-width 0;

    @include media-breakpoint-up(md) {
      padding: ($grid-gutter-width * 2) $grid-gutter-width $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      padding: 45px;
    }

    h2 {
      @include media-breakpoint-down(sm) {
        font-size: $h3-font-size;
      }
    }

    p {
      margin-left: auto;
      margin-right: auto;
      max-width: 400px;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-sm;
        padding-left: $gutter-md;
        padding-right: $gutter-md;
      }
    }
  }

  .footer {
    background-color: darken($body-bg, 7%);
  }
}
