.page-dashboard {
  #StartCTACard {
    @include media-breakpoint-up(md) {
      .card-body {
        display: flex;
        height: 181px;
      }
    }

    img {
      height: 60px;
    }

    h3 {
      max-width: 180px;

      @include media-breakpoint-up(lg) {
        max-width: 220px
      }
    }
  }

  .training-videos {
    padding-bottom: 24px;
    padding-top: 24px;

    h6 {
      margin-bottom: 24px;
    }
  }

  .video-link {
    color: $body-color;
    display: block;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-align: center;

    .video-preview {
      align-items: center;
      background-color: $gray-200;
      background-position: 50%;
      background-size: cover;
      border-radius: $border-radius;
      box-shadow: $box-shadow-dark;
      display: flex;
      height: 64px;
      justify-content: center;
      margin-bottom: $gutter-sm / 2;
      overflow: hidden;
      position: relative;
      transition: all 0.1s linear;

      &:after {
        background: rgba($primary, 0.75);
        border-radius: $border-radius-sm;
        content: " ";
        display: block;
        height: 30px;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 40px;
        transform: translate(-50%, -50%);
      }

      img {
        margin-left: 4px;
        position: relative;
        z-index: 1;
      }
    }

    &:hover {
      .video-preview {
        transform: scale(1.05);
      }
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
