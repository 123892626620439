.popover-inner {
    max-width: 90%;
    @media (min-width: 768px) {
        max-width: 400px;
    }
}
.bankName {
    position: relative;
    left: 0;
    bottom: 20px;
    font-size: 12px;
    margin: 5px 0 0;
    text-align: left;
    text-transform: uppercase;
    color: #3989e3;
    margin-top: -6px;
}