.icon-bubble {
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  box-shadow: $box-shadow-line, $box-shadow-sm;
  color: $gray-300;
  display: flex;
  flex-direction: column;
  font-size: $font-size-xs;
  flex-shrink: 0;
  justify-content: center;
  height: 50px;
  width: 50px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.15s linear;

  &.large {
    height: 90px;
    width: 90px;
  }

  h1, h2, h3, h4, h5 {
    color: $primary;
  }

  .badge {
    font-size: $font-size-sm;
    padding-top: 0.289rem;
  }

  &:not(.large) {
    .badge.top-right {
      right: -7px;
      top: -7px;
    }
  }

  small {
    font-weight: 700;
  }
}

a:hover .icon-bubble {
  box-shadow: $box-shadow-line-dark, $box-shadow-sm;
  transform: scale(1.07);
}
