.page-banners {
  .lu-banner-thumb {
    background-color: $gray-200;
    border-radius: $border-radius-sm;
    box-shadow: inset $box-shadow-line-dark;
    height: 0;
    overflow: hidden;
    position: relative;
    transition: all 0.2s linear;

    &.lu-banner-1 {
      padding-top: 70%;
      width: 90%;
    }

    &.lu-banner-2 {
      padding-top: 80%;
      width: 28%;
    }

    &.lu-banner-3 {
      padding-top: 12%;
      width: 90%;
    }

    &:after {
      background-color: $gray-300;
      box-shadow: inset $box-shadow-line-dark;
      content: " ";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s linear;
      width: 100%;
    }
  }

  .lu-banner-item:hover {
    .lu-banner-thumb {
      transform: scale(1.04);

      &:after {
        opacity: 1;
      }
    }
  }
}
