@import '../../../../../assets/styles/_variable.scss';
@import '../../../../../../node_modules/bootstrap/scss/bootstrap.scss';

.page-apply {
  h2 {
    u {
      border-bottom: 3px solid $danger;
      text-decoration: none;
    }
  }

  .cta-arrows {
    position: relative;

    &:before,
    &:after {
      background: url('../../../../../assets/icons/cta-arrow.svg') 50% no-repeat;
      background-size: contain;
      content: " ";
      display: block;
      height: 60px;
      right: -120px;
      position: absolute;
      top: -20px;
      width: 94px;

      @include media-breakpoint-down(sm) {
        height: 32px;
        right: -70px;
        top: -8px;
        width: 50px;
      }
    }
    &:before {
      left: -120px;
      right: auto;
      transform: scaleX(-1);

      @include media-breakpoint-down(sm) {
        left: -70px;
      }
    }
  }

  h1, h2, h3, h4 {
    color: #002147;
  }
}