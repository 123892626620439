.smarty-ui {
  top: 8px;
  left: 20px;
  position: absolute;
  z-index: 999;
  text-shadow: none;
  text-align: left;
  text-decoration: none;

  .smarty-popup {
    padding: 20px 30px;
    width: 360px;
    background: #FFFFFF;
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 2s;

    .smarty-popup-header {
      text-transform: uppercase;
      font: bold 10pt/1em "Helvetica", sans-serif;
      color: #CEA737;
      padding: 12px 0px 0px;
      text-align: center;
    }

    .smarty-popup-missing-input-header {
      color: #CEA737;
    }

    .smarty-popup-invalid-header {
      color: #D0021B;
    }

    .smarty-popup-typed-address {
      font-family: sans-serif;
      font-size: 10pt;
      font-style: italic;
      text-align: center;
      margin: 15px 0px;
    }

    .smarty-choice-alt {
      display: flex;
      justify-content: center;

      .smarty-choice {
        cursor: pointer;
      
        &:hover {
          background-color: #000 !important;
        }
      }

      .smarty-choice-abort, .smarty-choice-override {
        padding: 8px 10px;
        color: #FFF;
        font-size: 10pt;
        text-decoration: none;
        background: #606060;
        border-radius: 3px;
        border: none;
      }
    }
  }
}