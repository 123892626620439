@import 'src/assets/styles/variable.scss';

.button {
  margin-top: $lendingusa_spacing_md + 2;
  width: 200px;
}

:global {
  .rangeslider-horizontal {
    height: 4px;
    border-radius: 0;

    .rangeslider__handle {
      border: 2px solid #039958;
      border-radius: 50%;
      background-color: #04bc6c;
      box-shadow: 0 4px 15px 1px rgba(0,0,0,.2);
      width: 44px;
      height: 44px;

      &::after {
        display: none;
      }

      .rangeslider__handle-tooltip {
        width: 90px;
        height: 35px;
      }
    }
  }
  .rangeslider__fill {
    background-color: $light_gray !important;
  }
}

.encryption {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.noBottomMargin {
  margin-bottom: 0;
  margin-left: 10px;
}

.securityLock {
  width: 40px;
}

.borrowers-message {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;

  h3 {
    text-align: center !important;
    margin-bottom: 20px;
    margin-top: 30px;
    &::before {
      display: none;
    }
  }
}

.p-small {
  font-size: 16px;
}

.y-color {
  color: #f49f23;
  text-transform: capitalize;
  font-weight: bold;
}