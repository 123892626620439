label.custom-control-label {
  font-size: $font-size-sm;
  padding-top: 2px;
  line-height: 1.4;

  &::before,
  &::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
  }

  &::before {
    pointer-events: none;
    background-color: #fff;
    border: 2px solid #b0acbe;
  }
}

.form-control {
  &:disabled {
    cursor: no-drop;
  }
}