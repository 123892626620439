$personify-brand-teal: #00a499;
$personify-brand-orange: #ff8200;
$personify-brand-purple: #432c87;

.navbar-partner .navbar-brand {
  // background: url(/images/personify-logo-dark.png) 50% / contain no-repeat;
  margin: 0 auto;
  width: 180px;
}

.page-personify,
.modal-personify {
  &.narrow {
    margin: 0 auto;
    max-width: 800px;
  }

  p a,
  small a {
    color: $personify-brand-teal;
  }

  .text-primary {
    color: $personify-brand-orange !important;
  }

  .text-success {
    color: $personify-brand-teal !important;
  }

  .asterisk {
    color: $gray-300;
    font-size: 0.75em;
    font-weight: 400;
  }

  .text-link {
    color: $personify-brand-teal;
    cursor: pointer;
    text-decoration: underline;
  }

  .btn {
    &.btn-personify:not([disabled]) {
      background-color: $personify-brand-orange;
      color: $white;

      &:hover {
        background-color: darken($personify-brand-orange, 3%);
      }
    }

    &.active,
    &:active {
      background-color: $personify-brand-teal !important;
    }

    &.btn-secondary {
      color: $body-color;

      &:hover {
        color: $personify-brand-teal;

        &:active,
        &.active {
          color: $white !important;
        }
      }
    }
  }

  .btn-group .btn:hover {
    color: $personify-brand-teal;

    &:active,
    &.active {
      color: $white !important;
    }
  }

  small {
    display: block;
    font-size: 0.75em;
    line-height: 1.5;
  }

  .alert {
    &.alert-secondary {
      border: 2px solid $personify-brand-teal;
      background-color: lighten($personify-brand-teal, 50%);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $personify-brand-orange;
    border: 0;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    color: $white;
  }

  .card {
    &.bg-primary {
      background-color: $personify-brand-teal !important;

      .custom-control-label {
        font-size: $font-size-base;
        padding-top: 0;

        &:before {
          border: 0;
        }
      }
    }
  }

  .consent-form {
    > .row {
      border-bottom: 2px solid $gray-200;
      margin-bottom: 25px;

      p { margin-bottom: 25px; }
      .btn-group { margin-bottom: 25px; }
    }
  }

  .lu-offer-card {
    .btn {
      @include media-breakpoint-up(lg) {
        bottom: 25px;
      }
    }
  }

  .disclaimer-table {
    border: 2px solid $gray-200;
    border-radius: $border-radius;
    font-size: $font-size-sm;
    max-width: 100%;

    .row  {
      border-bottom: 1px solid $gray-200;

      &.header {
        border-bottom: 2px solid $gray-200;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      > div {
        padding: ($gutter-sm / 2) $gutter-sm (($gutter-sm / 2) + 2px);
      }
    }
  }

  .check-icon {
    list-style: none;

    li {
      margin-bottom: 15px;
      position: relative;

      &:before {
        // background: url(/icons/personify-check-circle.svg) 50% / 100% no-repeat;
        content: " ";
        display: block;
        height: 25px;
        left: -35px;
        position: absolute;
        top: -1px;
        width: 25px;
      }
    }
  }

  .check-example-img {
    margin: 10px -4% -20px;
    width: 108%;
  }

  .flag {
    &-merchant {
      background-color: $personify-brand-teal;
    }
    &-borrower {
      background-color: $personify-brand-purple;
    }
  }
}

.e-sign-placeholder {
  background-color: $gray-200;
  border-radius: $border-radius;
  height: 600px;
  margin: 0 $gutter-sm;
}
