@import 'src/assets/styles/variable.scss';

.button {
  margin-top: $lendingusa_spacing_md + 2;
}

.encryption {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.noBottomMargin {
  margin-bottom: 0;
  margin-left: 10px;
}

.securityLock {
  width: 40px;
}

.status {
  color: $accent_color;
}

.borrowers-message {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;
}

.heading {
  margin-bottom: 20px;
  margin-top: 30px;
}

.button {
  margin-top: 40px;
}