.smarty-tag-check {
  text-transform: uppercase;
  pointer-events: visible;
  position: absolute;
  top: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: 20px;

  &:hover {
    cursor: pointer;
    border-color: #333;
    transition: all 0.2s linear;
  }
}

.verifyText {
  width: 100%;
  text-align: center;
}

.smarty-ui-loading {
  position: absolute;
  z-index: 999;
  text-shadow: none;
  text-align: left;
  text-decoration: none;
  top: 18px;
  left: 100%;
  margin-left: -140px;
}

.smarty-dots {
  position: absolute;
  z-index: 999;
  width: 24px;
  height: 8px;
  background-image: url('../../icons/loading.gif');
}