.heading-div {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-wrapper {
        display: flex;
        img {
            margin-left: 10px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
    h2,
    h4 {
        margin-bottom: 25px;
    }
    .pick-payment-date-wrapper {
        padding: 20px;
        text-align: left;
        margin-top: 20px;
        background-color: #f6f7f7;
        .pick-payment-date-action-btn {
            width: 100%;
            margin-top: 10px;
            text-align: center;
        }
        .rdtPrev span,
        .rdtNext span {
            display: inline-block;
            transform: none;
        }
    }
    .dateWrapper {
        padding-top: 20px;
    }
}

