/* Bootstrap Overrides */
@import '../assets/styles/variable';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

/* Animate.css Library */
@import '../../node_modules/animate.css/animate.css';

/* Bootstrap + React Components */
@import '../assets/styles/components/animate';
@import '../assets/styles/components/badge';
@import '../assets/styles/components/button-group';
@import '../assets/styles/components/buttons';
@import '../assets/styles/components/card';
@import '../assets/styles/components/charts';
@import '../assets/styles/components/dropdown';
@import '../assets/styles/components/forms';
@import '../assets/styles/components/list-group';
@import '../assets/styles/components/verify-button';
@import '../assets/styles/components/popover';
@import '../assets/styles/components/smartStreetPopup';
@import '../assets/styles/components/modals';
@import '../assets/styles/components/pagination';
@import '../assets/styles/components/tables';
@import '../assets/styles/components/typography';
@import '../assets/styles/components/sliding-pane';
@import '../assets/styles/components/toast';
@import '../assets/styles/components/tooltip';
@import '../assets/styles/components/utilities';

/* Custom Components */
@import '../assets/styles/layout';
@import '../assets/styles/components/custom/header';
@import '../assets/styles/components/custom/footer';
@import '../assets/styles/components/custom/icon-bubble';
@import '../assets/styles/components/custom/loader';

/* Pages */
@import '../assets/styles/pages/application-detail';
@import '../assets/styles/pages/application';
@import '../assets/styles/pages/banners';
@import '../assets/styles/pages/brochures';
@import '../assets/styles/pages/dashboard';
@import '../assets/styles/pages/select-offer';
@import '../assets/styles/pages/find-merchant';
@import '../assets/styles/pages/personify';
