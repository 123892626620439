.inputLabel {
  width: 100%;

  span {
    background-color: #fff !important;
    em {
      top: -2px;
      padding: 1px 5px 2px;
    }
  }

  .input {
    margin: 0;
    font-size: 16px;
    padding: .375rem 1.125rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #3f556e;
    border-radius: 6px;
    background-color: white !important;

    &:focus {
      color: #3f556e;
      border-color: #a9ccf3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(57,137,227,.25);
    }
  }

  .tooltip {
    background-color: transparent;
    color: #002248;
    font-size: 14px;
    height: 44px;
    line-height: 1.4;
    max-width: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: text;

    i {
      background: url('../../../assets/icons/info.svg') 50% no-repeat;
      background-size: contain;
      bottom: 12px;
      display: block;
      height: 20px;
      position: absolute;
      right: 12px;
      width: 20px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }

    div {
      background: #ffffff;
      border-radius: 6px;
      bottom: 50px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15), 0 0 0 1px rgba(0,0,0,0.05);
      opacity: 0;
      padding: 10px 15px;
      pointer-events: none;
      position: absolute;
      right: 0;
      transition: all 0.15s linear;
      transform: translate3d(0,10px,0);
    }

    &.active div {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}