@import 'src/assets/styles/variable.scss';

.btn {
  border: 0;
  box-shadow: $box-shadow-dark;
  font-size: $font-size-sm;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: .813rem $grid-gutter-width;
  text-transform: uppercase;

  &.btn-sm {
    padding: .469rem $gutter-sm;
  }

  &.btn-lg {
    font-size: $font-size-base;
    padding: 1rem $gutter-lg;
  }

  &.btn-secondary {
    background-color: $white;
    color: $primary;

    &:hover {
      color: $gray-900;
    }
  }

  &.btn-link {
    box-shadow: none;

    &.dark {
      color: $white;
    }
  }

  &.disabled {
    background-color: $gray-200;
    box-shadow: none;
    color: $gray-600;
    cursor: no-drop;
  }

  &.muted {
    opacity: 0.25;
  }
}

.btn-icon {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0;
  width: 36px;
}

.call-btn {
  display: block;
  border: 2px solid #3989E3;
  border-radius: 5px;
  padding: 10px;
  color: #3989E3;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.enrollment-call-btn {
  display: block;
  padding: 10px;
  color: #3989E3;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}