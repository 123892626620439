.loader {
  height: 50px;
  position: relative;

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    .path {
      stroke: $primary;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite, colors 6s infinite;
    }
  }
}

@keyframes rotate {
  100% { transform: rotate(360deg); }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes colors {
  0%   { stroke: $primary; }
  25%  { stroke: $primary; }
  26%  { stroke: $danger; }
  50%  { stroke: $danger; }
  51%  { stroke: $primary; }
  75%  { stroke: $primary; }
  76%  { stroke: $danger; }
  100% { stroke: $danger; }
}
