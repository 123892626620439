.page-findmerchant{
  #FindMerchantResults {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }

    h3 {
      margin-bottom: -2px;
    }

    .results-item {
      border-bottom: 1px solid rgba($gray-200, 0.5);
      cursor: pointer;
      margin: 0;

      .list-count {
        border: 2px solid $gray-200;
        border-radius: 50%;
        color: $primary;
        flex-shrink: 0;
        font-weight: 600;
        height: 32px;
        line-height: 27px;
        margin-right: $gutter-sm;
        text-align: center;
        width: 32px;
      }

      &.selected {
        position: relative;

        &:before {
          background-color: $primary-light;
          border-radius: $border-radius;
          bottom: -1px;
          content: " ";
          display: block;
          left: -$grid-gutter-width;
          position: absolute;
          right: -$grid-gutter-width;
          top: -1px;
        }

        .list-count {
          border-color: $primary;
        }
      }
    }
  }

  #ContinueButtonContainer {
    background: linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding-top: 0;
    position: sticky;
    position: -webkit-sticky;
    pointer-events: none;
    right: 0;
    z-index: 20;

    #ContinueButton {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: $grid-gutter-width 12.5px;
      max-width: calc(400px - 25px);
      pointer-events: auto;
      width: calc(100% - 25px);

      img {
        margin-left: 12px;
        width: 16px;
      }
    }
  }

  label:not(.form-check-label):not(.custom-control-label) {
    color: #6c757d;
    font-size: .784rem;
    font-weight: 600;
    left: 20px;
    margin-bottom: 0;
    position: absolute;
    top: 8px;
    line-height: 1.4;
  }

  .form-group:not(.input-inline) .form-control {
    height: auto;
    padding: 22px 1.125rem .375rem;
    margin-bottom: 0;
    font-weight: 500;
  }

  .card .form-control, .slide-pane .form-control {
    background-color: #f6fafe !important;
  }

  form.inline-fill {
    border-radius: $border-radius;
  
    @include media-breakpoint-up(md) {
      background-color: $gray-200;
    }
  
    .connector {
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      line-height: 40px;
      padding: 0;
      text-align: center;
  
      @include media-breakpoint-up(md) {
        line-height: 54px;
      }
    }
  }

  select.form-control {
    background-image: none; 
  }
}
