.badge {
  min-width: 24px;
  padding: 0.386em 0.5em;
  text-align: center;

  &.top-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.badge-warning {
  color: $white;
}

.badge.outline {
  &.badge-success {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $success;
    color: $success;
  }
}

.table.table-sm .badge {
  font-size: $font-size-xs;
}

.flag {
  border-radius: $border-radius;
  color: $white;
  padding: .1em .4em 0.2em;

  &-merchant {
    @extend .flag;
    background-color: $danger;
  }
  &-borrower {
    @extend .flag;
    background-color: $primary;
  }
}
