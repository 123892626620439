.legal {
  margin: 0 auto !important;
  width: 100%;
}

.security {
  display: flex;
  justify-content: center;
  align-items: center;

  .comodoImage {
    margin-right: 30px;
  }
}

.copyright {
  padding-left: 15px;
  padding-right: 15px;

  p {
    align-self: center;
    margin-top: 35px;
    text-align: left;
  }
}

.creditOfferConfirmation {
  border-top: 0 !important;
  padding: 0 15px !important;
}

.dtc-footer {
  background: darken($light, 5%);
  bottom: 0;
  color: $gray-600;
  display: flex;
  font-size: $font-size-xs;
  height: 36px;
  justify-content: space-between;
  padding: 0.55rem $gutter-sm;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $gutter-lg;
    padding-right: $gutter-lg;
  }
}