.navbar {
  background-color: $white;
  box-shadow: $box-shadow;
  min-height: 60px;
  padding: 0 $gutter-sm;

  .navbar-brand {
    // background: url(/images/portal-logo.svg) 0 50% no-repeat;
    background-size: contain;
    height: 60px;
    margin: 0;
    padding: 0;
    width: 220px;
  }

  .envLabel {
    color: $lendingusa_gray3;
    font-weight: $font-weight-bold;
    padding-top: 5px;
    font-size: 18px;
    position: absolute;
    left: calc(100% + 12px);
    text-transform: uppercase;
  }

  .navbar-nav {
    .nav-link {
      font-weight: $font-weight-bold;
      transition: all 0.15s linear;

      svg g {
        transition: all 0.15s linear;
      }

      &:hover {
        color: $gray-900;

        svg g {
          fill: $gray-900 !important;
        }
      }

      &.active {
        color: $primary;

        svg g {
          fill: $primary !important;
        }
      }
    }
  }

  .nav-tel {
    color: $primary !important;
    letter-spacing: -0.04em;
  }

  @include media-breakpoint-down(md) {
    .navbar-nav {
      background-color: $light;
      left: -$grid-gutter-width;
      position: relative;
      width: calc(100% + 50px) !important;

      .nav-link {
        box-shadow: inset 0 1px 0 0 rgba($black, 0.075);
        padding: 0.813rem $grid-gutter-width;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  @include media-breakpoint-up(lg) {
    min-height: 70px;
    padding: 0 $gutter-lg;

    .navbar-brand {
      background-size: 284px;
      height: 70px;
      width: 284px;
    }

    .navbar-nav {
      .nav-item {
        display: flex;
      }

      .nav-link {
        align-items: center;
        display: flex;
      }

      .lu-main-nav {
        .nav-link {
          height: 70px;
          padding: 0 $gutter-md;
          position: relative;

          &:after {
            background-color: $gray-200;
            content: " ";
            display: block;
            height: 3px;
            left: $gutter-md;
            opacity: 0;
            position: absolute;
            right: $gutter-md;
            top: 0;
          }

          &.active:after {
            opacity: 1;
          }

          svg {
            margin-right: 10px;
            vertical-align: sub;
          }
        }
      }

      .lu-second-nav {
        align-items: center;

        .nav-item {
          height: 30px;

          .nav-link {
            padding-left: 12px;
            padding-right: 12px;
          }

          &:last-of-type .nav-link {
            border-left: $border-width solid $gray-200;
            margin: 0 0 0 ($grid-gutter-width - 12px);
            padding: 0 0 0 $grid-gutter-width;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .navbar-brand {
      width: 360px;
    }
  }

  .navbar-toggler {
    border: 0;
    padding: 0;
    position: relative;
    outline: none;
    transition: all 0.15s linear;

    &:after {
      bottom: -3px;
      border-radius: $border-radius-sm;
      box-shadow: 0 0 0 2px rgba($primary, 0.5);
      content: " ";
      left: -3px;
      opacity: 0;
      position: absolute;
      right: -3px;
      top: -3px;
      transition: opacity 0.15s linear;
    }

    &:focus:after {
      opacity: 1;
    }
  }
}

.dtc-header {
  box-shadow: $box-shadow;

  .navbar {
    @include media-breakpoint-up(sm) {
      min-height: 90px;
    }

    .navbar-brand {
      background: url('../../../icons/dtc-logo.svg') 0 50% no-repeat;
      background-size: contain;
      height: 77px !important;
      margin: 0;
      padding: 0;
      width: 263px !important;

      @include media-breakpoint-up(lg) {
        height: 77px !important;
        width: 263px !important;
      }

      @include media-breakpoint-down(xs) {
        height: 25px;
        background-position: center;
        background-size: 100%;
        max-width: 220px !important;
        flex: 1;
      }
    }

    .phone {
      h4 {
        @include media-breakpoint-down(xs) {
          font-size: $font-size-sm;
        }
      }
      h5 {
        margin-bottom: 4px;

        @include media-breakpoint-down(xs) {
          font-size: $font-size-sm;
          margin-bottom: 2px;
        }
      }
    }
  }

  .progress-bar {
    background-color: $gray-200;
    height: 4px;
    position: relative;

    .progress-fill {
      background-color: $primary;
      border-radius: 0 2px 2px 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 6px;

      .progress-fill {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}
