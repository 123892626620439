.borrowers-message {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;

  h2 {
    text-align: center !important;
    &::before {
      display: none;
    }
  }
}

.offer-confirmation {
  .title, .desc {
    max-width: 647px;
    margin-left: auto;
    margin-right: auto;
  }
}