.lu-offer-card {
  box-shadow: $box-shadow-dark;

  .lu-offer-cta {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .btn {
    margin-top: $grid-gutter-width;
    width: 100%;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 25px));
      right: $grid-gutter-width;
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      bottom: 75px;
      left: $grid-gutter-width;
      position: absolute;
      top: auto;
      transform: none;
      right: auto;
      width: calc(100% - 50px);
    }
  }
}

.lu-special-row {
  border-radius: $border-radius;
  font-size: $font-size-sm;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  .lu-title,
  .lu-offer {
    color: $white;
    font-weight: 700;
    padding: $gutter-sm;
  }

  .lu-title {
    background: darken($success, 5%) !important;
  }

  .lu-detail {
    font-weight: 600;
    padding: $gutter-sm;
  }
}
