.dropdown-menu {
  box-shadow: $box-shadow-dark;
}

.dropdown-toggle {
  &:after {
    background: url('../../icons/dropdown-arrow.svg') 50% no-repeat;
    border: 0;
    content: " ";
    display: inline-block;
    height: 7px;
    margin-left: 10px;
    vertical-align: inherit;
    width: 12px;
  }

  &.form-group {
    position: relative;

    .form-control {
      padding-right: 45px;
    }

    &:after {
      top: 0;
      height: 50px;
      pointer-events: none;
      position: absolute;
      right: 0;
      width: 50px;
    }
  }
}
