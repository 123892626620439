@import './variable';

a:hover {
  text-decoration: none;

  &::before {
    display: none;
  }
}

h3::before {
  display: none;
}

h2::before {
  display: none;
}

.text-align-center {
  text-align: center !important;
}

.text-left-override {
  @media screen and (max-width: 576px) {
    text-align: right !important;
  }
}

.text-center-override {
  @media screen and (max-width: 576px) {
    text-align: center !important;
  }
}

.border-right {
  @media screen and (max-width: 576px) {
    border-right: none !important;
  }
}

.border-light {
  border-color: #dbe3eb!important;
}

.label {
  background-color: transparent;
  color: #3F556E;
  padding: 0;
}

.full-width {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.embedImage {
  max-width: 50%;
}

.dateTimeInput {
  margin: 0;

  input {
    background-color: #fff !important;
    color: #3f556e !important;
    margin-bottom: 0;
    height: 44px;
  }
}

.loadingIndicator {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 20px;
}

.error {
  min-height: 16px;
  position: relative;
  left: 0;
  top: 0;
  padding-left: 18px;
  font-size: 12px;
  word-wrap: break-word;
  margin: ($lendingusa_spacing_xs - 7) 0 0;
  line-height: 1em;
  color: $accent_color;
  text-align: left;
  line-height: 1.2;
}

@media (min-width: 1280px) {
  .container {
      width: 1350px;
  }
}

@media (max-width: 576px) {
  .select-button-wrapper {
    padding: 0 47px !important;
  }
}

.sticky-outer-wrapper {
  &::before {
    display: none;
  }
}

.slider-cells {
  label {
    color: $gray-700;
  }

  .input {
    background-color: #fff !important;
  }
}

.rangeslider__handle {
  
  &:focus,
  &:active {
    outline: none;
  }

  &-tooltip {
    top: 55px !important;
    display: block !important;

    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid rgba(0, 0, 0, 0.8) !important;
      border-top: 0px !important;
      left: 50%;
      top: -8px;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 3px;
  background-color: #dbe3eb;
  box-shadow: none;
  margin: 32px 0;

  .label {
    margin-top: $lendingusa_spacing_xs - 2;
  }
  
  .input {
    font-size: 0.84rem;
    color: $main_black;
  }

  .rangeslider__handle {
    background-color: #3989e3;
    border: 2px solid #1e74d5;
    box-shadow: 0 4px 25px 0 rgba(0,33,71,.5);
    border-radius: 30px;
    width: 40px;
    cursor: grab;
    outline: none !important;
    height: 40px;
    z-index: 1;

    &::after {
      display: none;
    }

    .rangeslider__handle-tooltip {
      width: 90px;
      height: 35px;
    }

    &:hover {
      transform: translate(-50%,-50%) scale(1.2);
    }
  }

  .rangeslider__fill {
    display: none;
    background-color: $light_gray !important;
  }

  .rangeslider__label-item {
    transform: translateX(-35px) !important;
    margin-top: 15px;

    &::before {
      background-color: #BCBCC9;
      content: " ";
      display: block;
      height: 10px;
      left: 15px;
      position: absolute;
      top: -13px;
      width: 1px;
    }
  }
}

.rangeslider__fill {
  background-color: $light_gray !important;
}

label > input.requestedAmount {
  background-color: #f6fafe !important;
  color: #3989e3;
  font-size: 2rem;
  font-weight: 600;
  height: 70px;
  text-align: center;
}

.form-control:focus {
  color: #3f556e;
  background-color: #fff;
  border-color: #a9ccf3 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57,137,227,.25) !important;
}

.flexViewHorizontal {
  display: flex;
}

.flexViewVertical {
  display: flex;
  flex-direction: column;
}

/*!
  * https://github.com/YouCanBookMe/react-datetime
*/

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  width: 300px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
  position: absolute;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 28px;
  vertical-align: top;
  position: relative;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.Select-menu-outer {
  z-index: 10000;
}

.submit-button {
  font-size: 1rem;
  padding: 1rem 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewDetailCheckbox {
  color: #002147;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}

.setigo-image {
  width: auto !important;
  height: 40px;
}

.text-primary {
  color: #3989E3 !important;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.popover-container {
  display: flex;
  .text-primary {
    label {
      color: #3989E3;
    }
  }

  .text-link {
    label {
      text-decoration: underline;
      cursor: pointer;
      margin: 0 0 12px 0;
    }
  }
}

.loading-container {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.errorSection {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: red;
}

.offer-modal-disclaimer {
  br {
    content: '';
    height: 0.5rem;
    display: block;
  }
}

.btnSetValues {
  line-height: 1;
  border: 1px solid #3989E3;
}

.checkRateTitle {
  display: flex;
  align-items: flex-start;

  span {
    color: inherit;
    font-size: 26px;
  }
}

.setValuesWrapper {
  > div {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  > span {
    color: #3989E3;
    font-weight: 700;
    padding-left: 5px;
    margin-top: 5px;
    display: block;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.termsModal {
  .modal-body {
    max-height: 450px;
    overflow: auto;
    padding: 0 25px;
    margin: 25px 0;
  }
}

.input-loader {
  position: absolute;
  top: 10px;
  right: 30px;
  border: 2px solid #c1c1c1;
  border-top: 2px solid #242aa2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: loader-spin 2s linear infinite;
}

@keyframes loader-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.input-status {
  position: absolute;
  top: 10px;
  right: 30px;

  img {
    width: 24px;
    height: 24px;
  }
}
