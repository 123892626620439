.popover {
  padding-bottom: $lendingusa_spacing_xs - 2;
  max-width: 600px !important;
  margin-right: 20px;

  h3 {
    &::before {
      display: none;
    }
    font-size: 1.5rem;
    font-weight: normal;
  }

  .error {
    padding-left: 22px;
    font-size: 14px;
    color: $accent_color;
  }
  
  .checkbox {
    display: flex;
  }
  
  .input {
    margin-top: $lendingusa_spacing_xs - 7;
  }
  
  .label {
    padding: 0 !important;
    line-height: 1rem;
    border-bottom: 1px solid black;
    margin-top: 4px;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  .scrollBar {
    height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px;
  
    &::-webkit-scrollbar {
      -webkit-appearance: none;
  
      @media screen and (max-width: 768px) {
        width: 30px; 
      }
    }
  
    ::-webkit-scrollbar:vertical {
      width: 24px;
    }
  
    ::-webkit-scrollbar:horizontal {
        height: 24px;
    }
  
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
      height: 30px;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #666;
    }
  }
  
  .button {
    margin-top: 20px;
  }
}

.popover-container {
  .checkbox {
    display: flex;

    .custom-checkbox {
      label.custom-control-label {
        margin: 0;
      }
    }
  }
}